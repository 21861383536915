import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
import { AccountDataService } from "@swagger/services";
import { Observable, map, of, from } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class UniqueUsername implements AsyncValidator {
  constructor(private accountData: AccountDataService){}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return from(this.accountData.isUsernameTaken(control.value)).pipe(map((res) => res ? {notUniqueUsername: true} : null),
    catchError(() => of(null))
    );
  }
}
