import { FormControl } from "@angular/forms";

export function hexColorValidator(control: FormControl) {
    const value = control.value;
    if (!value) {
      return null;
    }
  
    // error if not a valid hex color
    if (!/^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
      return { hex: false };
    }
  
    return null;
  }