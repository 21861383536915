import { Pipe } from '@angular/core';

export function decodeStr(str) {
  return decodeURIComponent(str);
}

@Pipe({ name: 'decodeStr', standalone: true, pure: true })
export class DecodeStrPipe {
  transform(str: string): string {
    return decodeStr(str);
  }
}
