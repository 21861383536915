import { AbstractControl, ValidatorFn } from '@angular/forms';

export const isArrayValidator: ValidatorFn = (control: AbstractControl) => {
  if (!Array.isArray(control.value)) {
    return { isArray: true };
  }

  return null;
};

export const minArrayLengthValidator = (minLength: number): ValidatorFn => {
  return (control: AbstractControl) => {
    if (control.value.length < minLength) {
      return { minArrayLength: true };
    }

    return null;
  };
};

export const maxArrayLengthValidator = (maxLength: number): ValidatorFn => {
  return (control: AbstractControl) => {
    if (control.value.length > maxLength) {
      return { maxArrayLength: true };
    }

    return null;
  };
};
