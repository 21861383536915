import { Pipe } from "@angular/core";

export function encodeStr(str: string) {
    return encodeURIComponent(str);
}

@Pipe({ name: "encodeStr" ,standalone: true, pure: true})
export class EncodeStrPipe {
    transform(str: string): string {
        return encodeStr(str);
    }
}