import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

function parseTime(time: string): number {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
}

export function timeComparisonValidator(
  controlName1: string,
  controlName2: string
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const control1 = group.get(controlName1);
    const control2 = group.get(controlName2);

    if (!control1 || !control2) {
      return null;
    }

    const time1 = control1.value;
    const time2 = control2.value;

    if (!time1 || !time2) {
      return null;
    }

    const time1Parsed = parseTime(time1);
    const time2Parsed = parseTime(time2);

    if (time1Parsed > time2Parsed) {
      return { timeComparison: true };
    }

    return null;
  };
}

export function timeEqualsValidator(
  controlName1: string,
  controlName2: string
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const control1 = group.get(controlName1);
    const control2 = group.get(controlName2);

    if (!control1 || !control2) {
      return null;
    }

    const time1 = control1.value;
    const time2 = control2.value;

    if (!time1 || !time2) {
      return null;
    }

    const time1Parsed = parseTime(time1);
    const time2Parsed = parseTime(time2);

    if (time1Parsed === time2Parsed) {
      return { timeEquals: true };
    }

    return null;
  };
}

// Validator function to check if a control's time value is smaller than another control's time value
export function smallerThanTime(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const currentValue = control.value;
    const otherValue = otherControl.value;

    if (!currentValue || !otherValue) {
      return null; // if either value is missing, no error
    }

    const currentTime = parseTime(currentValue);
    const otherTime = parseTime(otherValue);

    if (currentTime >= otherTime) {
      return { smallerThanTime: true };
    }

    return null;
  };
}

export function smallerThanTimeName(otherControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const otherControl = control.parent?.get(otherControlName);
    if (!otherControl?.value) {
      return null;
    }
    return smallerThanTime(otherControl)(control);
  };
}

// Validator function to check if a control's time value is greater than another control's time value
export function greaterThanTime(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const currentValue = control.value;
    const otherValue = otherControl.value;

    if (!currentValue || !otherValue) {
      return null; // if either value is missing, no error
    }

    const currentTime = parseTime(currentValue);
    const otherTime = parseTime(otherValue);

    if (currentTime <= otherTime) {
      return { greaterThanTime: true };
    }

    return null;
  };
}

export function greaterThanTimeName(otherControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const otherControl = control.parent?.get(otherControlName);
    if (!otherControl?.value) {
      return null;
    }
    return greaterThanTime(otherControl)(control);
  };
}


