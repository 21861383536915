import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNegative',
  standalone: true,
})
export class FormatNegativePipe implements PipeTransform {
  transform(value: number): string {
    return value < 0 ? `- ${Math.abs(value)}` : value.toString();
  }
}

@Pipe({
  name: 'formatNegativeAndPositive',
  standalone: true,
})
export class FormatNegativeAndPositivePipe implements PipeTransform {
  transform(value: number): string {
    if (value === 0) {
      return `${value}`; // Returns "0" with no symbol
    }
    return value < 0 ? `- ${Math.abs(value)}` : `+ ${value}`;
  }
}
