import { Time } from "@angular/common";
import { Injectable } from "@angular/core";


@Injectable({ providedIn: 'root' })
export class TimeService {

  createTimeIntervals(interval: number, startTime: Time, endTime: Time) {
    const timeIntervals: Time[] = [];
    let time = { ...startTime };
    while (time.hours < endTime.hours || (time.hours === endTime.hours && time.minutes <= endTime.minutes)) {
      timeIntervals.push({ ...time });
      time = this.addMinutes(time, interval);
    }

    if(endTime.hours === 24){
      timeIntervals.push({ hours: 23, minutes: 59 });
    }

    return timeIntervals;
  }


  addMinutes(time: Time, minutes: number) {
    let newTime = { ...time };
    newTime.minutes += minutes;
    if (newTime.minutes >= 60) {
      newTime.hours += 1;
      newTime.minutes -= 60;
    }
    return newTime;
  }


  timeToString(time: Time) {
    //add 0 to minutes and hours if they are less than 10
    return `${time.hours < 10 ? '0' : ''}${time.hours}:${time.minutes < 10 ? '0' : ''}${time.minutes}`;
  }

}


