import { Pipe, PipeTransform } from '@angular/core';
import { MAX_DATE } from '@shared/constants';
import moment, { Moment } from 'moment';

@Pipe({
    name: 'isMaxDate',
    standalone: true
})

export class IsMaxDatePipe implements PipeTransform {
    transform(value: Date | Moment | string): boolean {

        if(!value) { 
            return false;
        }

        return moment(value).isSame(MAX_DATE, 'day');  
    }
}

@Pipe({
    name: 'isNotMaxDate',
    standalone: true
})

export class IsNotMaxDatePipe implements PipeTransform {
    transform(value: Date | Moment | string): boolean {

        if(!value) { 
            return true;
        }

        return !moment(value).isSame(MAX_DATE, 'day');  
    }
}