import {
  AbstractControl,
  FormArray,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import moment from 'moment';

export function greaterThan(startControl: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const end = control.value;
    const start = startControl.value;

    if (!end && !start) {
      return null;
    }

    if (new Date(start) >= new Date(end)) {
      return { greaterThan: true };
    }
    return null;
  };
}

export function smallerThan(endControl: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const start = control.value;
    const end = endControl.value;

    if (!end && !start) {
      return null;
    }

    if (new Date(start) >= new Date(end)) {
      return { smallerThan: true };
    }
    return null;
  };
}

export function invalidDateRange(): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const start = group.get('ab')?.value;
    const end = group.get('bis')?.value;

    if (!end && !start) {
      return null;
    }

    if (new Date(start) >= new Date(end)) {
      return { greaterThan: true };
    }
    return null;
  };
}

export function dateComparisonValidator(
  controlName1: string,
  controlName2: string
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const control1 = group.get(controlName1);
    const control2 = group.get(controlName2);

    if (!control1 || !control2) {
      return null;
    }

    const date1 = control1.value;
    const date2 = control2.value;

    if (!date1 || !date2) {
      return null;
    }

    if (new Date(date1) > new Date(date2)) {
      return { dateComparison: true };
    }

    return null;
  };
}


