import { FormGroup } from '@angular/forms';

export function getDirtyValues(form: any) {
  let dirtyValues = {} as any;

  Object.keys(form.controls).forEach((key) => {
    let currentControl = form.controls[key];

    if (currentControl.dirty) {
      if (currentControl.controls)
        dirtyValues[key] = getDirtyValues(currentControl);
      else dirtyValues[key] = currentControl.value;
    }
  });

  return dirtyValues;
}

export function resetFormState(formGroup: FormGroup): void {
  formGroup.markAsPristine(); // Setzt den dirty Status zurück
  formGroup.markAsUntouched(); // Setzt den touched Status zurück

  for (let control in formGroup.controls) {
    formGroup.controls[control].markAsPristine();
    formGroup.controls[control].markAsUntouched();
  }
}


export function isArrayLike(obj: any): boolean {
  return (
    typeof obj === 'object' &&
    Object.keys(obj).every((key) => !isNaN(Number(key)))
  );
}
