import { Pipe, PipeTransform } from '@angular/core';
import { MIN_DATE } from '@shared/constants';
import moment, { Moment } from 'moment';

@Pipe({
    name: 'isMinDate',
    standalone: true
})

export class IsMinDatePipe implements PipeTransform {
    transform(value: Date | Moment | string): boolean {

        if(!value) { 
            return false;
        }

        return moment(value).isSame(MIN_DATE, 'day');  
    }
}

@Pipe({
    name: 'isNotMinDate',
    standalone: true
})

export class IsNotMinDatePipe implements PipeTransform {
    transform(value: Date | Moment | string): boolean {

        if(!value) { 
            return true;
        }

        return !moment(value).isSame(MIN_DATE, 'day');  
    }
}