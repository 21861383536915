import { Time } from '@angular/common';
import moment from 'moment';

export function getTime(date: Date): Time {
  return {
    hours: date?.getHours(),
    minutes: date?.getMinutes(),
  };
}

export function mapStringToTime(time: string): Time {
  return {
    hours: moment(time, 'HH:mm')?.hours(),
    minutes: moment(time, 'HH:mm')?.minutes(),
  };
}

export function greaterEqualTime(first: Time, second: Time) {
  return (
    first?.hours > second?.hours ||
    (first?.hours === second?.hours && first?.minutes >= second?.minutes)
  );
}

export function greaterTime(first: Time, second: Time) {
  return (
    first?.hours > second?.hours ||
    (first?.hours === second?.hours && first?.minutes > second?.minutes)
  );
}

export function lessTime(first: Time, second: Time) {
  return (
    first?.hours < second?.hours ||
    (first?.hours === second?.hours && first?.minutes < second?.minutes)
  );
}

export function lessEqualTime(first: Time, second: Time) {
  return (
    first?.hours < second?.hours ||
    (first?.hours === second?.hours && first?.minutes <= second?.minutes)
  );
}

export function timeToMinutes(time: Time) {
  return time.hours * 60 + time.minutes;
}

export function greaterEqualTimeMinutes(first: Time, second: Time) {
  return timeToMinutes(first) >= timeToMinutes(second);
}

export function lessEqualTimeMinutes(first: Time, second: Time) {
  return timeToMinutes(first) <= timeToMinutes(second);
}
